<header>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
    <a class="navbar-brand" href="#" routerLink="/">
      <img src="assets/images/handson.png" alt="logo" with="40" height="40">
    </a>

    <ul class="navbar-nav ml-auto">
      <li *ngIf="vars.isAuthenticated() && (vars.isAdmin())" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" href="#" routerLink="/admin">myHandsOn
          <span *ngIf="vars.count > 0" class="badge badge-danger badge-pill">{{vars.count}}</span>
          &nbsp;
        </a>
      </li>
      <li *ngIf="vars.isAuthenticated() && vars.isClient()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" href="#" routerLink="/client">myHandsOn
          <span *ngIf="vars.count > 0" class="badge badge-danger badge-pill">{{vars.count}}</span>
          &nbsp;
        </a>
      </li>
      <li *ngIf="vars.isAuthenticated() && vars.isProvider()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" href="#" routerLink="/provider">myHandsOn
          <span *ngIf="vars.count > 0" class="badge badge-danger badge-pill">{{vars.count}}</span>
          &nbsp;
        </a>
      </li>
    </ul>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
      aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li *ngIf="!vars.isAuthenticated()" class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/">Home</a>
        </li>
        <li *ngIf="!vars.isAuthenticated()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/about">About</a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li *ngIf="vars.isSysAdmin()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="fa fa-cog nav-link" aria-hidden="true" data-toggle="tooltip" title="Configure" href="#" routerLink="/configure"></a>
        </li>
        <li *ngIf="(vars.isSysAdmin() || vars.isAdmin() || vars.isClient())" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="fa fa-users nav-link" aria-hidden="true" data-toggle="tooltip" title="Manage" href="#" routerLink="/manage"></a>
        </li>
        <li *ngIf="!vars.isAuthenticated()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/register">Register</a>
        </li>
        <li *ngIf="!vars.isAuthenticated()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" routerLink="/login">Login</a>
        </li>
        <li *ngIf="vars.isAuthenticated()" class="nav-item">
          <div *ngIf="!vars.isMultiRole()" class="nav-link">{{userLabel()}}</div>
          <div *ngIf="vars.isMultiRole()" class="nav-link">{{userLabel()}}<a class="fa fa-hand-o-left nav-link"
              aria-hidden="true" data-toggle="tooltip" title="Switch Role" (click)="switchRole()"></a></div>
        </li>
        <li *ngIf="vars.isAuthenticated()" class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" href="#" (click)="onLogoutClick()">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</header>