import { Component, OnInit } from '@angular/core';
import { VarsService } from '../../services/vars.service'; // shared data service

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {

  constructor(
    public vars: VarsService
  ) {
  }

  ngOnInit() {
  }

}
