import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

import { User } from '../../models/User';
import { Group } from '../../models/Group';
import { Role } from '../../models/Role';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  roles: Role[];
  groups: Group[]; // list of all groups
  users: User[]; // list of all users
  group: Group; // group buffer
  filter_group_list_rid: number;
  filter_group_rid: number;

  constructor(
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    this.filter_group_list_rid = 0;
    this.filter_group_rid = 0;
    // the only new group roles allowed are client and provider
    this.roles = [{ rid: 3, name: 'client' }, { rid: 4, name: 'provider' }];
    // instantiate a new group buffer
    this.group = { gid: 0, rid: 0, rid_name: '', name: '', notes: '', code: '', shared: 0, users: [] };
  }

  ngOnInit() {
    // load groups array
    this.authService.getGroups(0, 0).subscribe(data => {
      if (data['status'] === 'success') {
        this.groups = data['groups'];
      }
    });
    // load users array
    this.authService.getGroupUsers(0).subscribe(data => {
      if (data['status'] === 'success') {
        this.users = data['users'];
      }
    });
  }

  // set role selection filter for groups
  onFilterGroupListSelect(rid) {
    this.filter_group_list_rid = rid;
  }
  // set role selection filter for groups
  onFilterGroupSelect(rid) {
    this.filter_group_rid = rid;
    this.group.rid = rid;
  }

  // return label of the selected option
  getOptionLabel(group) {
    if (group.rid_name === '') { // group role has not been set yet.
      return 'Select';
    } else { // return role corresponding to the rid.
      return group.rid_name;
    }
  }

  // flip the shared indicator
  onChecked() {
    this.group.shared = this.group.shared == 1 ? 0 : 1
  }

  // update group role
  onRoleUpdate(idx) {
    this.group.rid = this.roles[idx].rid;
    this.group.rid_name = this.roles[idx].name;
  }

  // set currently selected group
  onGroupSelect(idx) {
    this.filter_group_rid = this.groups[idx].rid;
    this.group.gid = this.groups[idx].gid;
    this.group.rid = this.groups[idx].rid;
    this.group.rid_name = this.groups[idx].rid_name;
    this.group.name = this.groups[idx].name;
    this.group.notes = this.groups[idx].notes;
    this.group.code = this.groups[idx].code;
    this.group.shared = this.groups[idx].shared;
    // load user array
    this.authService.getGroupUsers(this.group.gid).subscribe(data => {
      if (data['status'] === 'success') { // return users or empty array
        this.group.users = data['users'];
      }
    });
  }

  onUserSelect(idx) { // add feature to group
    this.group.users.push(this.users[idx]);
  }
  isNotSelected(uid) {
    for (const item of this.group.users) {
      if (item.uid === uid) {
        return false;
      }
    }
    return true;
  }
  onUserDelete(idx) { // remove feature from the group
    this.group.users.splice(idx, 1);
  }
  // clear the rule buffer
  onNewClick() {
    this.filter_group_rid = 0;
    this.group.gid = 0;
    this.group.rid = 0;
    this.group.rid_name = '';
    this.group.name = '';
    this.group.notes = '';
    this.group.code = '';
    this.group.shared = 0;
    this.group.users = [];
  }
  // update group in DB
  onSubmitClick() {
    this.authService.updateGroup(this.group).subscribe(data => {
      if (data['status'] === 'success') { // update successful
        this.group.gid = data['value'];
        // re-load groups array
        this.authService.getGroups(0, 0).subscribe(data => {
          if (data['status'] === 'success') { // return rules or empty array
            this.groups = data['groups'];
            this.modalService.open(ModalComponent).componentInstance.message = 'Records Updated';
          }
        });
      } else {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }
}
