import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})

export class ResetComponent implements OnInit {
  password: string
  password2: string

  constructor(
    private router: Router,
    private authService: AuthService,
    private vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
      this.password = '';
      this.password2 = '';
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.password === this.password2) {
        this.authService.resetPassword(this.vars.token, this.password).subscribe(data => {
            if (data['status'] === 'success') {
                this.vars.token = ''; // clear the token
                this.modalService.open(ModalComponent).componentInstance.message = data['value'];
                this.router.navigate(['/login']);
            } else { // display error
                // display modal error
                this.modalService.open(ModalComponent).componentInstance.message = data['value'];
            }
        });
    } else {
        this.modalService.open(ModalComponent).componentInstance.message = "Passwords don't match!";
    }
    this.vars.isLoggedIn = false;
  }

}
