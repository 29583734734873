<div class="container">
    <br>
    <div class="row">
        <div class="col-md-4 cellA">
            <div class="form-group row">
                <div class="col-12">
                    <select class="custom-select form-control" #filterUserList (change)="onFilterUserListSelect(filterUserList.value)">
                        <option [value]="1">Active</option>
                        <option [value]="0">Inactive</option>
                    </select>
                </div>
            </div>
            <div class="row cellListA">
                <div class="col">
                    <ul class="list-group">
                        <ng-container *ngFor="let item of this.users; let i = index;">
                            <li *ngIf="item.status == this.filter_user_list_status"
                                class="list-group-item" [ngClass]="{'active': this.user.uid == item.uid}" (click)="onUserSelect(i)">{{item.name}}
                                <small class="text-muted">({{item.uid}})</small>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
            <div class="row cellLoad">
                <div class="col-6">
                    <input type="file" id="fileInput" (change)="onFileChange($event)">
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-success btn-sm" (click)="onLoadClick()">Load</button>
                </div>
            </div>
        </div>
        <div class="col-md-8 cellB">
            <form>
                <div class="input-group row text-info">
                    <div class="col-8">
                        User ID:
                        <b *ngIf="this.user != null" class="text-primary">{{user.uid}}</b>
                    </div>
                    <div class="col-4 form-group form-check">
                        <input type="checkbox" class="form-check-input" [checked]="user.verified == 1" (change)="onChecked()">
                        <label class="form-check-label text-info col-form-label">Verified</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="statusSelect" class="text-info col-form-label">Status:</label>
                            <select name="statusSelect" class="custom-select form-control" #statusSelect (change)="onStatusSelect(statusSelect.value)">
                                <option [value]="0">Select</option>
                                <option [value]="1" [selected]="this.user.status == 'active'">active</option>
                                <option [value]="2" [selected]="this.user.status == 'inactive'">inactive</option>
                            </select>
                        </div>
                        <div class="form-group row">
                            <label for="userName" class="text-info col-form-label">Name:</label>
                            <input type="text" class="form-control" placeholder="Enter user name" [(ngModel)]="user.name"
                                name="userName">
                        </div>
                        <div class="form-group row">
                            <label for="userEmail" class="text-info col-form-label">Email:</label>
                            <input type="text" class="form-control" placeholder="Enter user email" [(ngModel)]="user.email"
                                name="userEmail">
                        </div>
                        <div class="form-group row">
                            <label for="userLogin" class="text-info col-form-label">Login:</label>
                            <input type="text" class="form-control" placeholder="Enter user login (optional)"
                                [(ngModel)]="user.login" name="userLogin">
                        </div>
                        <div class="form-group row">
                            <label for="userPassword" class="text-info col-form-label">Password:</label>
                            <input type="password" class="form-control" placeholder="Enter user password" [(ngModel)]="user.password"
                                name="userPassword">
                        </div>
                        <br>
                        <div class="form-group row">
                            <div class="col">
                                <button class="btn btn-primary" (click)="onNewClick()">New</button>
                                &nbsp;
                                <button *ngIf="this.user.verified != 1 && this.user.email != ''" class="btn btn-info"
                                    (click)="onVerifyClick()">Verify</button>
                                &nbsp;
                                <button class="btn btn-success" (click)="onSubmitClick()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>