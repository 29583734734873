import { Pipe } from '@angular/core';

@Pipe({
    name: 'ActionModule'
})
export class ActionModule {
    transform(objects: any[]): any[] {
        if (objects) {
            return objects.filter(object => {
                return object.url !== '';
            });
        }
    }
}
