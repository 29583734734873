import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // angular bootstrap
import { ModalComponent } from '../modal/modal.component'; // custom modal
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'; // custom confirm modal
import { DatePipe } from '@angular/common';

import { Action } from '../../models/Action';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.css']
})
export class ProviderComponent implements OnInit {
  status: string; // action status filter
  action: Action; // action buffer
  notes: string; // current user notes
  closeResult: string;
  dt: number;

  constructor(
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal, // instantiate modal service
    public datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.onStatusSelect(1); // defaults to CONFIRMED
    //let timeInUTC = new Date().toUTCString();
    this.dt = Math.round((new Date().getTime()) / 1000); // get current unix timestamp (in UTC!)
  }
  // initialize buffer
  initBuffer() {
    this.notes = '';
    this.action = {
      aid: 0,
      category: {
        cid: 0,
        name: ''
      },
      service: {
        sid: 0,
        name: '',
        provider_gid: 0
      },
      name: '',
      notes: '',
      required: 1,
      providers: [],
      client: { uid: 0, name: '' },
      features: [],
      timestamp: 0
    };
  }

  // action status selected
  onStatusSelect(idx) { // 1 = confirmed, 2 = canceled, 3 = closed
    this.vars.action_idx = -1; // clear idx until new submited or specific action is selected
    this.initBuffer(); // initialize/clear action buffer
    this.status = this.vars.status[idx];
    this.authService.getActionList(this.status, this.vars.user.uid, this.vars.user.roles[this.vars.rid_idx].rid).subscribe(data => {
      if (data['status'] === 'success') { // populate actions for this user-role
        this.vars.user.roles[this.vars.rid_idx].actions = data['actions'];
      }
    });
  }

  // action selected from the menu
  onActionClick(idx) {
    this.vars.action_idx = idx; // set currently selected action (used by getAction())
    this.authService.getAction(this.vars.user.roles[this.vars.rid_idx].actions[idx].aid).subscribe(data => {
      if (data['status'] === 'success') { // populate actions data
        this.vars.user.roles[this.vars.rid_idx].actions[idx] = data['action'];

        this.action.category = this.vars.getAction().category;
        this.action.service = this.vars.getAction().service;
        this.action.name = this.vars.getAction().name;
        this.action.notes = this.vars.getAction().notes;
        this.action.required = this.vars.getAction().required;
        this.action.client = this.vars.getAction().client;
        this.action.status = this.vars.getAction().status;
        this.action.features = this.vars.getAction().features;
        this.action.timestamp = this.vars.getAction().timestamp;
        this.action.aid = this.vars.getAction().aid; // last to prevent errors
        this.loadActionProviders(true); // list currently assigned providers
      }
    });
  }

  // load a list of all selected providers (if load is true) or just clear the list
  loadActionProviders(load) {
    var providers = null;
    this.action.providers = [];
    // if this is an existing action then get a list of any current assignments
    if (load && this.vars.getAction() != null) {
      providers = this.vars.getAction().providers;
    }
    for (let x = 0; x < this.action.required; x++) {
      if (providers != null && providers[x] != null) {
        this.action.providers.push(providers[x]);
        if (providers[x].uid == this.vars.user.uid) { // current user
          this.notes = providers[x].action_notes; // copy notes into buffer
        }
      }
    }
  }

  // load set of features for this request category
  loadFeatures(cid) {
    this.authService.getFeatures(cid, '', '').subscribe(data => {
      if (data['status'] === 'success') {
        this.action.features = data['features'];
      }
    });
  }

  // format date
  displayDate(date) {
    if (date == null) {
      return "N/A";
    } else {
      return ('0000' + date.year).slice(-4) + '-' + ('00' + date.month).slice(-2) + '-' + ('00' + date.day).slice(-2);
    }
  }

  // format time
  displayTime(time) {
    if (time == null) {
      return "N/A";
    } else {
      return ('00' + time.hour).slice(-2) + ' : ' + ('00' + time.minute).slice(-2);
    }
  }

  // return label of the selected option
  getOptionLabel(feature) {
    if (feature.value == null || feature.value == '') { // feature value has not been set yet.
      return "Select";
    } else { // return label corresponding to the value.
      return feature.options[feature.value].label;
    }
  }

  // flip the checkbox indicator
  onFeatureChecked(feature) {
    if (feature.value == null || feature.value === '' || feature.value === '0') {
      this.onFeatureUpdate(feature, '1');
    } else {
      this.onFeatureUpdate(feature, '0');
    }
  }

  // on every change
  onFeatureUpdate(feature, value) {
    feature.value = value;
  }

  // get a number of providers selected
  getConfirmed(action) {
    let cnt = 0;
    for (let x = 0; x < action.providers.length; x++) {
      if (action.providers[x].uid != 0) {
        cnt++;
      }
    }
    return cnt;
  }

  // iterrate over required features and return true if any missing
  stillRequired() {
    for (let feature of this.action.features) {
      if (feature.required == 1 && (feature.value == '' || feature.value == '0')) {
        return true;
      }
    }
    return false;
  }

  // get user action status of the current user
  providerStatus() {
    for (let x = 0; x < this.action.providers.length; x++) {
      if (this.action.providers[x].uid == this.vars.user.uid) {
        return this.action.providers[x].action_status;
      }
    }
    return false;
  }

  // check if action expires soon
  urgent(action) {
    if (action !== null && action.status != 'canceled' && action.status != 'closed') {
      if ((action.timestamp > this.dt) && ((action.timestamp - this.dt) < 86400)) { // action is due in less than 24 hours from now
        return true;
      }
    }
    return false;
  }


  // decide if the current feature is visible
  isVisible(feature) {
    if (feature.hidden == 0) { // feature always visible
      return true;
    }
    for (let user of this.action.providers) { // for all providers listed under current action
      if (user.uid == this.vars.user.uid && user.action_status == "accepted") { // if the current user is one of those accepted this action
        return true;
      }
    }
    return false;
  }

  // update user action with status
  updateUserAction(status) {
    this.authService.updateUserAction(this.vars.user.uid, this.action.aid, status, this.notes).subscribe(data => {
      if (data['status'] === 'success') { // update successful
        this.modalService.open(ModalComponent).componentInstance.message = data['value']; // display message
        this.vars.action_idx = -1; // revert to no action selected
        this.onStatusSelect(1); // return to CONFIRMED
        // refresh count
        this.authService.getActionCount(this.vars.user.uid, this.vars.user.roles[this.vars.rid_idx].rid).subscribe(data => {
          if (data['status'] === 'success') {
            this.vars.count = data['count'];
          }
        });
      } else {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }

  // process button click
  onButtonClick(button) {
    switch (button) {
      case 'update': { // update notes
        this.updateUserAction('');
        break;
      }
      case 'accept': { // accept action assignment
        // modal confirmation dialog
        const md = this.modalService.open(ModalConfirmComponent);
        md.componentInstance.message = 'Are you sure you want ACCEPT this request?';
        md.result.then((userResponse) => {
          if (userResponse) {
            this.updateUserAction('accepted');
          }
        });
        break;
      }
      case 'reject': { // accept action assignment
        // modal confirmation dialog
        const md = this.modalService.open(ModalConfirmComponent);
        md.componentInstance.message = 'Are you sure you want to REJECT this request?';
        md.result.then((userResponse) => {
          if (userResponse) {
            this.updateUserAction('rejected');
          }
        });
        break;
      }
      case 'cancel': { // set action to canceled and set all corresponding user actions to canceled
        // modal confirmation dialog
        const md = this.modalService.open(ModalConfirmComponent);
        md.componentInstance.message = 'Are you sure you want to CANCEL your assignment?';
        md.result.then((userResponse) => {
          if (userResponse) {
            this.updateUserAction('canceled');
          }
        });
        break;
      }
      case 'complete': { // set action to closed and update all corresponding user actions to completed
        // modal confirmation dialog
        const md = this.modalService.open(ModalConfirmComponent);
        md.componentInstance.message = 'Are you sure you want to COMPLETE your assignment?';
        md.result.then((userResponse) => {
          if (userResponse) {
            this.updateUserAction('completed');
          }
        });
        break;
      }
      default: {
        break;
      }
    }
  }
}