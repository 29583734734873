<div class="container">
    <br>
    <div class="row">
        <div class="col-md-4 cellA">
            <div class="row cellListA">
                <div class="col">
                    <ul class="list-group">
                        <ng-container *ngFor="let item of this.vars.services; let i = index;">
                            <li class="list-group-item" [ngClass]="{'active': this.service.sid == item.sid}"
                                (click)="onServiceSelect(i)">{{item.name}}
                                <small class="text-muted">({{item.sid}})</small>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 cellB">
            <form>
                <div class="row text-info">
                    <div class="col-12">
                        Service ID:
                        <b *ngIf="this.service != null" class="text-primary">{{service.sid}}</b>
                    </div>
                </div>
                <br>
                <div class="row">
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="servicePriority" class="text-info col-form-label">Display Priority:
                                    <b class="text-primary">{{service.priority}}</b>
                                </label>
                                <input type="range" min="0" value="0" max="100" class="form-control" [(ngModel)]="service.priority"
                                    name="servicePriority">
                            </div>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="serviceName" class="text-info col-form-label">Name:</label>
                            <input type="text" class="form-control" placeholder="Enter service name"
                                [(ngModel)]="service.name" name="serviceName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="providerGroupSelect" class="text-info col-form-label">Provider Group:</label>
                            <select name="providerGroupSelect" class="custom-select form-control" #providerGroup (change)="onProviderGroupUpdate(providerGroup.value)">
                                <option [value]="0">Select</option>
                                <ng-container *ngFor="let group of this.provider_groups; let i = index">
                                    <option [value]="group.gid" [selected]="group.gid == this.service.provider_gid">{{group.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="accessSelect" class="text-info col-form-label">Access:</label>
                            <select name="accessSelect" class="custom-select form-control" #accessType
                                (change)="onAccessUpdate(accessType.value)">
                                <option [value]="0">All</option>
                                <option [value]="1" [selected]="this.service.access == 'group'">Group</option>
                                <option [value]="2" [selected]="this.service.access == 'user'">User</option>
                            </select>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="this.service.access == 'group' && this.service.groups.length > 0">
                    <div class="row text-info">Groups:</div>
                    <div class="row">
                        <div class="col-12 cellListB">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let group of this.service.groups; let i = index;"
                                    (click)="onGroupDelete(i)">{{group.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="this.service.access == 'user' && this.service.users.length > 0">
                    <div class="row text-info">Users:</div>
                    <div class="row">
                        <div class="col-12 cellListB">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let user of this.service.users; let i = index;"
                                    (click)="onUserDelete(i)">{{user.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <br>
                <div class="form-group row">
                    <div class="col-12">
                        <button class="btn btn-primary" (click)="onNewClick()">New</button>
                        &nbsp;
                        <button class="btn btn-success" (click)="onSubmitClick()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-4 cellB">
            <ng-container *ngIf="this.service.access == 'group'">
                <div class="row">
                    <div class="col text-center text-info">
                        Available Groups:
                    </div>
                </div>
                <div class="row cellListA">
                    <div class="col">
                        <ul class="list-group">
                            <ng-container *ngFor="let item of this.client_groups; let i = index;">
                                <li class="list-group-item" *ngIf="this.groupNotSelected(item.gid)"
                                    (click)="onGroupSelect(item)">{{item.name}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="this.service.access == 'user'">
                <div class="row">
                    <div class="col text-center text-info">
                        Available Users:
                    </div>
                </div>
                <div class="row cellListA">
                    <div class="col">
                        <ul class="list-group">
                            <ng-container *ngFor="let item of this.client_users; let i = index;">
                                <li class="list-group-item" *ngIf="this.userNotSelected(item.uid)"
                                    (click)="onUserSelect(item)">{{item.name}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>