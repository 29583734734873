import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { VarsService } from './vars.service';

@Injectable()
export class AuthService {

  constructor(public http: HttpClient, public vars: VarsService) {
  }

  getAuth() {
    return this.http.get(this.vars.serviceURL + '?action=status', { withCredentials: true });
  }
  login(email, password) {
    return this.http.get(this.vars.serviceURL + '?action=login&email=' + email + '&password=' + password, { withCredentials: true });
  }
  forgotPassword(email) {
    return this.http.get(this.vars.serviceURL + '?action=email_reset&email=' + email, { withCredentials: true });
  }
  resetPassword(token, password) {
    return this.http.get(this.vars.serviceURL + '?action=reset&token=' + token + '&password=' + password, { withCredentials: true });
  }
  verifyUser(token) {
    return this.http.get(this.vars.serviceURL + '?action=verify&token=' + token, { withCredentials: true });
  }

  logout() {
    this.vars.isLoggedIn = false;
    return this.http.get(this.vars.serviceURL + '?action=logout', { withCredentials: true });
  }
  register(name, email, password, code) {
    return this.http.get(this.vars.serviceURL + '?action=register&name=' + name + '&email=' + email + '&password=' + password + '&code=' + code, { withCredentials: true });
  }
  // ---USERS
  getAccessList(cid, sid) {
    return this.http.get(this.vars.serviceURL + '?action=get_access_list&cid=' + cid + '&sid=' + sid, { withCredentials: true });
  }
  getRoleUsers(rid) {
    return this.http.get(this.vars.serviceURL + '?action=get_users&gid=0&aid=0&rid=' + rid, { withCredentials: true });
  }
  getGroupUsers(gid) {
    return this.http.get(this.vars.serviceURL + '?action=get_users&gid=' + gid + '&aid=0&rid=0', { withCredentials: true });
  }
  getActionUsers(aid) {
    return this.http.get(this.vars.serviceURL + '?action=get_users&gid=0&aid=' + aid + '&rid=0', { withCredentials: true });
  }
  updateUser(user) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_user',
      user: user
    }, { headers: headers, params: params });
  }
  updateUserGroup(gid, users) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_user_group',
      gid: gid,
      users: users
    }, { headers: headers, params: params });
  }
  // process users
  processUsers(userInput) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'process_users',
      users: userInput
    }, { headers: headers, params: params });
  }
  // ---ROLES
  getRoles() {
    return this.http.get(this.vars.serviceURL + '?action=get_roles&uid=0', { withCredentials: true });
  }
  // ---GROUPS
  getGroups(uid, rid) {
    return this.http.get(this.vars.serviceURL + '?action=get_groups&uid=' + uid + '&rid=' + rid, { withCredentials: true });
  }
  updateGroup(group) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_group',
      group: group
    }, { headers: headers, params: params });
  }
  // ---ACTIONS
  getActionCount(uid, rid) {
    return this.http.get(this.vars.serviceURL + '?action=get_action_count&uid=' + uid + '&rid=' + rid, { withCredentials: true });
  }
  getActionList(status, uid, rid) {
    return this.http.get(this.vars.serviceURL + '?action=get_action_list&status=' + status + '&uid=' + uid + '&rid=' + rid, { withCredentials: true });
  }
  getAction(aid) {
    return this.http.get(this.vars.serviceURL + '?action=get_action&aid=' + aid, { withCredentials: true });
  }
  updateAction(action) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_action',
      action_object: action
    }, { headers: headers, params: params });
  }
  updateUserAction(uid, aid, status, notes) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_user_action',
      uid: uid,
      aid: aid,
      status: status,
      notes: notes
    }, { headers: headers, params: params });
  }
  // ---CATEGORIES
  getCategories(cid, rid) {
    return this.http.get(this.vars.serviceURL + '?action=get_categories&cid=' + cid + '&rid=' + rid, { withCredentials: true });
  }
  updateCategory(category) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_category',
      category: category
    }, { headers: headers, params: params });
  }
  // ---SERVICES
  getServices(sid, rid) {
    return this.http.get(this.vars.serviceURL + '?action=get_services&sid=' + sid + '&rid=' + rid, { withCredentials: true });
  }
  updateService(service) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_service',
      service: service
    }, { headers: headers, params: params });
  }
  // ---FEATURES
  getFeatures(cid, aid, uid) {
    return this.http.get(this.vars.serviceURL + '?action=get_features&cid=' + cid + '&aid=' + aid + '&uid=' + uid, { withCredentials: true });
  }
  updateFeature(feature) {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams().set('withCredentials', 'true');
    return this.http.post(
      this.vars.serviceURL, {
      action: 'update_feature',
      feature: feature
    }, { headers: headers, params: params });
  }
  // ---REPORTS 
  getReports(idx) {
    return this.http.get(this.vars.serviceURL + '?action=get_reports&' + 'id=' + idx, { withCredentials: true });
  }
}
