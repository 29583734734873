import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

import { User } from '../../models/User';
import { Action } from '../../models/Action';
import { UserInput } from '../../models/UserInput';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  filter_user_list_status: string;
  users: User[];
  user: User; // current user buffer
  header: string; // header labels of the currently selected user type
  file: Blob;
  userInput: UserInput[]; // user input file buffer

  constructor(
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    this.filter_user_list_status = 'active';
    // instantiate a new user buffer
    this.user = { uid: 0, status: 'active', name: '', email: '', login: '', password: '', verified: 0 };
    this.header = '';
    this.userInput = [];
  }

  ngOnInit() {
    // load users array
    this.authService.getGroupUsers(0).subscribe(data => {
      if (data['status'] === 'success') { // return users or empty array
        this.users = data['users'];
      }
    });
  }

  // set status selection filter for users
  onFilterUserListSelect(value) {
    if (value == 1) {
      this.filter_user_list_status = 'active';
    } else {
      this.filter_user_list_status = 'inactive';
    }
  }

  // set user status
  onStatusSelect(value) {
    if (value == '0') {
      this.user.status = '';
    } else if (value == '1') {
      this.user.status = 'active';
    } else {
      this.user.status = 'inactive';
    }
  }

  // set currently selected user
  onUserSelect(idx) { // copy the user data into the current user buffer
    this.user.uid = this.users[idx].uid;
    this.user.status = this.users[idx].status;
    this.user.name = this.users[idx].name;
    this.user.email = this.users[idx].email;
    this.user.login = this.users[idx].login;
    this.user.password = this.users[idx].password;
    this.user.verified = this.users[idx].verified;
  }

  // flip the verified indicator
  onChecked() {
    this.user.verified = this.user.verified == 1 ? 0 : 1
  }

  // clear the user buffer
  onNewClick() {
    this.user.uid = 0;
    this.user.status = 'active';
    this.user.name = '';
    this.user.email = '';
    this.user.login = '';
    this.user.password = '';
    this.user.verified = 0;
  }

  // send verification email
  onVerifyClick() {
    // IMPLEMENT THIS!
  }

  // update user in DB
  onSubmitClick() {
    this.authService.updateUser(this.user).subscribe(data => {
      if (data['status'] === 'success') { // update successful
        this.user.uid = data['uid'];
        // re-load users array
        this.authService.getGroupUsers(0).subscribe(data => {
          if (data['status'] === 'success') { // return users or empty array
            this.users = data['users'];
            this.modalService.open(ModalComponent).componentInstance.message = "Records Updated";
          }
        });
      } else {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }

  // set the file blob if new file selected
  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
    } else {
      this.file = null;
    }
  }

  // load file content and process it
  onLoadClick() {
    if (this.file != null) {
      this.userInput = []; // clear the input buffer
      let reader = new FileReader();
      reader.readAsText(this.file);
      reader.onload = (e) => {
        let csvData = <string>reader.result;
        let csvRecordsArray = csvData.split(/\r0|\r\n|\n/);
        let i = 0;
        // for each record set in the file
        for (let row of csvRecordsArray) { // process each row
          let col = row.split(',');
          if (Number(col[1]) > 0) { // skip all rows that do not contain a valid number in group column
            this.userInput[i] = { 'gid': Number(col[1]), 'uid': Number(col[2]), 'name': col[3], 'email': col[4], 'password': col[5], 'verified': Number(col[6]), 'status': col[7]};            
            i++;
          }
        }
        // console.log(JSON.stringify(this.userInput));
        if (i > 0) { // we have user record to process
          this.authService.processUsers(this.userInput).subscribe(data => {
            if (data['status'] === 'success') { // processing successful
              this.modalService.open(ModalComponent).componentInstance.message = data['value'];
            } else {
              this.modalService.open(ModalComponent).componentInstance.message = data['value'];
            }
          });
        }
      };
    }
  }
}
