<div class="container">
    <br>
    <div class="row">
        <div class="col-md-4 cellA">
            <div class="form-group row">
                <div class="col-12">
                    <select class="custom-select form-control" #filterCategory (change)="onFilterCategorySelect(filterCategory.value)">
                        <option [value]="0">All</option>
                        <ng-container *ngFor="let category of vars.categories; let i = index">
                            <option [value]="category.cid">{{category.name}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="row cellListA">
                <div class="col">
                    <ul class="list-group">
                        <ng-container *ngFor="let item of this.features; let i = index;">
                            <li *ngIf="this.filter_cid == 0 || this.filter_cid == item.cid" class="list-group-item" [ngClass]="{'active': this.feature.fid == item.fid}" (click)="onFeatureSelect(i)">{{item.name}}
                                <small class="text-muted">({{item.fid}})</small>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-8 cellB">
            <form>
                <div class="row text-info">
                    <div class="col-6">
                        Feature ID:
                        <b *ngIf="this.feature != null" class="text-primary">{{feature.fid}}</b>
                    </div>
                    <div class="col-6 form-check">
                        <input type="checkbox" class="form-check-input" id="requiredCheck" [checked]="feature.required == 1"
                            (change)="onCheckedRequired()">
                        <label class="form-check-label text-info" for="requiredCheck">Required</label>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="featurePriority" class="text-info col-form-label">Display Priority:
                                <b class="text-primary">{{feature.priority}}</b>
                            </label>
                            <input type="range" min="0" value="0" max="100" class="form-control" [(ngModel)]="feature.priority"
                                name="featurePriority">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="categorySelect" class="text-info col-form-label">Request Category:</label>
                            <select name="categorySelect" class="custom-select form-control" #featureCategory (change)="onCategoryUpdate(featureCategory.value)">
                                <option [value]="0">Select</option>
                                <ng-container *ngFor="let category of this.vars.categories; let i = index">
                                    <option [value]="category.cid" [selected]="category.cid == this.feature.cid">{{category.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="typeSelect" class="text-info col-form-label">Item (Feature) Type:</label>
                            <select name="typeSelect" class="custom-select form-control" #featureType (change)="onTypeUpdate(featureType.value)">
                                <option [value]="0">Select</option>
                                <ng-container *ngFor="let type of vars.type; let i = index">
                                    <option [value]="type" [selected]="type == this.feature.type">{{type}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <ng-container *ngIf="this.feature.type == 'select'">
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="row feature_option">
                                        <div class="col-sm-6">
                                            <input type="text" class="form-group" placeholder="Enter option label"
                                                [(ngModel)]="feature_option.label" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-sm-1">
                                            <a class="fa fa-plus-circle nav-link" aria-hidden="true" (click)="onPlusClick()"></a>
                                        </div>
                                        <div class="col-sm-5">
                                            &nbsp;
                                        </div>
                                    </div>
                                </li>
                                <li *ngFor="let option of this.feature.options; let i = index" class="list-group-item">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <input type="text" class="form-group" placeholder="Enter option label"
                                                [(ngModel)]="this.feature.options[i].label" [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="col-sm-1">
                                            <a class="fa fa-minus-circle nav-link" aria-hidden="true" (click)="onMinusClick(i)"></a>
                                        </div>
                                        <div class="col-sm-5">
                                            &nbsp;
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </ng-container>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="featureName" class="text-info col-form-label">Name:</label>
                            <input type="text" class="form-control" placeholder="Enter feature name" [(ngModel)]="feature.name"
                                name="featureName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="featureNotes" class="text-info col-form-label">Notes: </label>
                            <textarea rows="4" class="form-control form-control-sm" placeholder="Enter feature notes"
                                [(ngModel)]="feature.notes" name="featurenNotes"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="featureMaximum" class="text-info col-form-label">Maximum (if applicable):</label>
                            <input name="featureMaximum" type="text" class="form-control" placeholder="Enter feature maximum"
                                [(ngModel)]="feature.maximum">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="hiddenSelect" class="text-info col-form-label">Hidden from:</label>
                            <select name="hiddenSelect" class="custom-select form-control" #featureHidden (change)="onHiddenUpdate(featureHidden.value)">
                                <option [value]="0">None</option>
                                <option [value]="1" [selected]="this.feature.hidden == 1">Client</option>
                                <option [value]="2" [selected]="this.feature.hidden == 2">Provider</option>
                                <option [value]="3" [selected]="this.feature.hidden == 3">Both</option>
                              </select>
                        </div>
                    </div>
                </div>
                <br>
                <div class="form-group row">
                    <div class="col-12">
                        <button class="btn btn-primary" (click)="onNewClick()">New</button>
                        &nbsp;
                        <button class="btn btn-success" (click)="onSubmitClick()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>