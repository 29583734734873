<div class="modal-container">
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <ng-container *ngIf="isString(message)">
      <!-- single message -->
      {{message}}
    </ng-container>
    <ng-container *ngIf="!isString(message)">
      <!-- multiple lines -->
      <ul class="list-unstyled">
        <li *ngFor="let row of message">{{row}}</li>
      </ul>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close(0)">No</button>
    <button type="button" class="btn btn-success" (click)="modal.close(1)">Yes</button>
  </div>
</div>