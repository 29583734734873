import { Pipe } from '@angular/core';

@Pipe({
    name: 'UserTeacher'
})
export class UserTeacher {
    transform(objects: any[]): any[] {
        if (objects) {
            return objects.filter(object => {
                return object.role === 'teacher';
            });
        }
    }
}