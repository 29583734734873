<div *ngIf="!reset" class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card">
      <div class="card-block">
        <img class="mx-auto d-block" width="40%" height="auto" src="assets/images/handson.png" alt="">
        <h2 class="text-center">HandsOn USF Login</h2>
        <form (submit)="onSubmit()">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" [(ngModel)]="email" name="email" required>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" [(ngModel)]="password" name="password" required>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary btn-block" value="Submit">
            <!-- SSO deferred to later version
            <input type="submit" class="btn btn-success btn-block" value="USF NetID Login" -->
          </div>
          <div class="form-group">
              <a class="nav-link" [routerLink]="" (click)="onReset()">Would you like to reset your password?</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="reset" class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card">
      <div class="card-block">
        <img class="mx-auto d-block" width="40%" height="auto" src="assets/images/handson.png" alt="Logo">
        <h2 class="text-center">HandsOn USF Password Reset</h2>
        <form (submit)="onSubmitReset()">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" [(ngModel)]="email" name="email" required>
          </div>
          <div class="form-group">
              <input type="submit" class="btn btn-primary btn-block" value="Submit">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>