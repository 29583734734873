import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    this.route.queryParams.subscribe(params => {
      if (params['action'] !== undefined) {
        this.vars.emailAction = params['action'];
      } else {
        this.vars.emailAction = '';
      }
      if (params['token'] !== undefined) {
        this.vars.token = params['token'];
      } else {
        this.vars.token = '';
      }
    });
  }

  ngOnInit() {
    if (this.vars.emailAction === 'reset' && this.vars.token !== '') { // navigate to password reset
      this.router.navigate(['/reset']);
    } else if (this.vars.emailAction === 'verify' && this.vars.token !== '') { // navigate to verify-user
      this.authService.verifyUser(this.vars.token).subscribe(data => {
        if (data['status'] === 'success') {
          this.vars.emailAction = ''; // clear the action
          this.vars.token = ''; // clear the token
          this.modalService.open(ModalComponent).componentInstance.message = data['value'];
          this.router.navigate(['/login']);
        } else { // display error
          this.modalService.open(ModalComponent).componentInstance.message = data['value'];
        }
      });
    }
  }
}
