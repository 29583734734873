import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent {
  @Input() message;


  constructor(public modal: NgbActiveModal) {}

  isString(val) { 
    return typeof val === 'string'; 
  }
}




