import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // angular bootstrap
import { ModalComponent } from '../modal/modal.component'; // custom modal
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'; // custom confirm modal
import { DatePipe } from '@angular/common';

import { Action } from '../../models/Action';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})

export class ClientComponent implements OnInit {
  gid_num: number;
  cid_num: number;
  sid_num: number;
  status: string; // action status filter
  action: Action; // action buffer
  closeResult: string;
  dt: number;

  constructor(
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal, // instantiate modal service
    public datepipe: DatePipe,
  ) {
  }

  ngOnInit() {
    this.gid_num = this.vars.groups.length; // number of groups for this user
    this.cid_num = this.vars.categories.length; // number of categories for this user
    this.sid_num = this.vars.services.length; // number of services for this user
    this.onStatusSelect(1); // defaults to CONFIRMED
    //let timeInUTC = new Date().toUTCString();
    this.dt = Math.round((new Date().getTime()) / 1000); // get current unix timestamp (in UTC!)
  }
  // initialize action buffer
  initBuffer() {
    this.action = {
      aid: 0,
      group: {
        gid: 0,
        rid: 0,
        name: 'None'
      },
      category: {
        cid: 0,
        name: ''
      },
      service: {
        sid: 0,
        name: '',
        provider_gid: 0
      },
      name: '',
      notes: '',
      required: 1,
      providers: [],
      client: { uid: 0, name: '' },
      status: 'open',
      features: [],
      timestamp: 0
    };
  }

  // action status selected
  onStatusSelect(idx) { // -1 = new, 0 = open, 1 = confirmed, 2 = canceled, 3 = closed
    // every time status is selected, clear the buffer and create a New record if idx < 0
    this.vars.action_idx = -1; // clear idx until new submited or specific action is selected
    this.initBuffer(); // initialize/clear action buffer
    if (idx < 0) { // create new action instance when New (-1) is selected
      this.status = 'new';
      if (this.gid_num > 0) { // set to first group's gid
        this.action.group = this.vars.groups[0];
      }
      if (this.cid_num > 0) { // set to first category
        this.action.category = this.vars.categories[0];
      }
      if (this.sid_num > 0) { // set to first service
        this.action.service = this.vars.services[0];
      }
      this.action.client.uid = this.vars.user.uid; // default action owner is the current user
      this.action.client.name = this.vars.user.name;
      this.setActionName(); // calculate new action name based on the timestamp
      this.loadFeatures(this.action.category.cid);// get client action features for this category
      this.loadActionProviders(false);
    } else { // update status AND load the list  
      this.status = this.vars.status[idx];
      this.authService.getActionList(this.status, this.vars.user.uid, this.vars.user.roles[this.vars.rid_idx].rid).subscribe(data => {
        if (data['status'] === 'success') { // populate actions for this user-role
          this.vars.user.roles[this.vars.rid_idx].actions = data['actions'];
        }
      });
    }
  }

  // action selected from the menu
  onActionClick(idx) {
    this.vars.action_idx = idx; // set currently selected action (used by getAction())
    this.authService.getAction(this.vars.user.roles[this.vars.rid_idx].actions[idx].aid).subscribe(data => {
      if (data['status'] === 'success') { // populate actions data
        this.vars.user.roles[this.vars.rid_idx].actions[idx] = data['action'];

        this.action.group = this.vars.getAction().group;
        this.action.category = this.vars.getAction().category;
        this.action.service = this.vars.getAction().service;
        this.action.name = this.vars.getAction().name;
        this.action.notes = this.vars.getAction().notes;
        this.action.required = this.vars.getAction().required;
        this.action.client = this.vars.getAction().client;
        this.action.status = this.vars.getAction().status;
        this.action.features = this.vars.getAction().features;
        this.action.timestamp = this.vars.getAction().timestamp;
        this.action.aid = this.vars.getAction().aid; // last to prevent errors
        this.loadActionProviders(true); // list currently assigned providers
      }
    });
  }

  // load a list of all selected providers (if load is true) or just clear the list
  loadActionProviders(load) {
    var providers = null;
    this.action.providers = [];
    // if this is an existing action then get a list of any current assignments
    if (load && this.vars.getAction() != null) {
      providers = this.vars.getAction().providers;
    }
    for (let x = 0; x < this.action.required; x++) {
      if (providers != null && providers[x] != null) {
        this.action.providers.push(providers[x]);
      }
    }
  }

  // set name of the new action
  setActionName() {
    const date = new Date();
    const name = this.datepipe.transform(date, 'yyMMdd-HHmmss') + '-' + this.vars.user.uid;
    this.action.name = name;
  }

  // load set of features for this request category
  loadFeatures(cid) {
    this.authService.getFeatures(cid, '', '').subscribe(data => {
      if (data['status'] === 'success') {
        this.action.features = data['features'];
      }
    });
  }

  // select new Request Group (should only happen when status is New)
  onGroupSelect(group) {
    this.action.group = group; // update the group
  }
  // select new Request Category (should only happen when status is New)
  onCategorySelect(category) {
    this.action.category = category; // update the category
    this.action.client.uid = this.vars.user.uid; // default action owner is the current user
    this.action.client.name = this.vars.user.name;
    this.setActionName(); // calculate new action name based on the timestamp
    this.loadFeatures(this.action.category.cid); // get client action features for this category
  }
  // selected new Service Type
  onServiceSelect(service) {
    this.action.service = service;
    // clear the list of ALL assigned providers
    this.loadActionProviders(false); // clear current assignments
  }

  // format date
  displayDate(date) {
    if (date == null) {
      return "N/A";
    } else {
      return ('0000' + date.year).slice(-4) + '-' + ('00' + date.month).slice(-2) + '-' + ('00' + date.day).slice(-2);
    }
  }

  // format time
  displayTime(time) {
    if (time == null) {
      return "N/A";
    } else {
      return ('00' + time.hour).slice(-2) + ' : ' + ('00' + time.minute).slice(-2);
    }
  }

  // return label of the selected option
  getOptionLabel(feature) {
    if (feature.value == null || feature.value == '') { // feature value has not been set yet.
      return "Select";
    } else { // return label corresponding to the value.
      return feature.options[feature.value].label;
    }
  }

  // flip the checkbox indicator
  onFeatureChecked(feature) {
    if (feature.value == null || feature.value === '' || feature.value === '0') {
      this.onFeatureUpdate(feature, '1');
    } else {
      this.onFeatureUpdate(feature, '0');
    }
  }

  // on every change
  onFeatureUpdate(feature, value) {
    feature.value = value;
  }

  // get a number of providers selected
  getConfirmed(action) {
    let cnt = 0;
    for (let x = 0; x < action.providers.length; x++) {
      if (action.providers[x].uid != 0) {
        cnt++;
      }
    }
    return cnt;
  }

  // iterrate over required features and return true if any missing
  stillRequired() {
    for (let feature of this.action.features) {
      if (feature.required == 1 && (feature.value == '' || feature.value == '0')) {
        return true;
      }
    }
    return false;
  }

  // check if action expires soon
  urgent(action) {
    if (action !== null && action.status != 'canceled' && action.status != 'closed') {
      if ((action.timestamp > this.dt) && ((action.timestamp - this.dt) < 86400)) { // action is due in less than 24 hours from now
        return true;
      }
    }
    return false;
  }

  updateAction() {
    this.authService.updateAction(this.action).subscribe(data => {
      if (data['status'] === 'success') {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
        this.vars.action_idx = -1; // revert to no action selected
        this.onStatusSelect(1); // return to CONFIRMED
        // refresh count
        this.authService.getActionCount(this.vars.user.uid, this.vars.user.roles[this.vars.rid_idx].rid).subscribe(data => {
          if (data['status'] === 'success') {
            this.vars.count = data['count'];
          }
        });
      } else {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }

  // process button click
  onButtonClick(button) {
    switch (button) {
      case 'submit': // create or update an action
        this.updateAction();
        break;
      case 'cancel': // set action to canceled and set all corresponding user actions to canceled
        // modal confirmation dialog
        const md = this.modalService.open(ModalConfirmComponent);
        md.componentInstance.message = 'Are you sure you want to CANCEL this request?';
        md.result.then((userResponse) => {
          if (userResponse) {
            this.action.status = 'canceled';
            this.updateAction();
          }
        });
        break;
      case 'add': // duplicate current request
        this.status = 'new'; // jump to new
        this.setActionName(); // create new name
        this.action.aid = 0; // reset aid - status will be open
        this.vars.action_idx = -1; // clear action selected index
        break;
      default:
        break;
    }
  }
}
