import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})

export class ModalConfirmComponent {
  @Input() message;

  constructor(public modal: NgbActiveModal) { }

  isString(val) { 
    return typeof val === 'string'; 
  }

  
}
