<div class="container">
    <br>
    <div class="row">
        <div class="col-md-4 cellA">
            <div class="form-group row">
                <div class="col-12">
                    <select class="custom-select form-control" #filterGroupList (change)="onFilterGroupListSelect(filterGroupList.value)">
                        <option [value]="0">All</option>
                        <ng-container *ngFor="let role of vars.roles; let i = index">
                            <option *ngIf="vars.isSysAdmin() || role.name !== 'sysadmin'" [value]="role.rid">{{role.name}}</option>
                        </ng-container>
                    </select>
                </div>
            </div>
            <div class="row cellListA">
                <div class="col">
                    <ul class="list-group">
                        <ng-container *ngFor="let item of this.groups; let i = index;">
                            <li *ngIf="(filter_group_list_rid == 0 || item.rid == this.filter_group_list_rid) && (vars.isSysAdmin() || item.rid_name !== 'sysadmin')"
                                class="list-group-item" [ngClass]="{'active': this.group.gid == item.gid, 'list-group-item-primary': item.rid == 3, 'list-group-item-success': item.rid == 4}" (click)="onGroupSelect(i)">{{item.name}}
                                <small class="text-muted">({{item.rid_name}})</small>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 cellB">
            <form>
                <div class="row text-info">
                    <div class="col-6">
                        Group ID:
                        <b *ngIf="this.group != null" class="text-primary">{{group.gid}}</b>
                    </div>
                    <div *ngIf="group.rid == 3" class="col-6 form-group form-check">
                        <input type="checkbox" class="form-check-input" [checked]="group.shared == 1" (change)="onChecked()">
                        <label class="form-check-label text-info col-form-label">Shared Requests</label>
                    </div>
                    <div *ngIf="group.rid != 3">
                        &nbsp;
                    </div>
                </div>
                &nbsp;
                <div class="row">
                    <div class="col-12">
                        <div *ngIf="this.group.rid_name != 'sysadmin' && this.group.rid_name != 'admin'" class="form-group row">
                            <label for="groupSelect" class="text-info col-form-label">Role:</label>
                            <select name="groupSelect" class="custom-select form-control" #filterGroup (change)="onFilterGroupSelect(filterGroup.value)">
                                <option [value]="0">Select</option>
                                <ng-container *ngFor="let role of vars.roles; let i = index">
                                    <option *ngIf="role.name !== 'sysadmin' && role.name !== 'admin'" [value]="role.rid" [selected]="role.rid == this.filter_group_rid">{{role.name}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div *ngIf="this.group.rid_name == 'sysadmin' || this.group.rid_name == 'admin'" class="form-group row">
                            <label for="groupSelect" class="text-info col-form-label">Category:</label>
                            <input name="groupSelect" type="text" class="form-control" [(ngModel)]="group.rid_name" disabled>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="groupName" class="text-info col-form-label">Name:</label>
                            <input type="text" class="form-control" placeholder="Enter group name" [(ngModel)]="group.name" name="groupName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="groupnotes" class="text-info col-form-label">Notes: </label>
                            <textarea rows="4" class="form-control form-control-sm" placeholder="Enter group notes" [(ngModel)]="group.notes" name="groupnotes"></textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="groupName" class="text-info col-form-label">Code:</label>
                            <input type="text" class="form-control" placeholder="Enter group code" [(ngModel)]="group.code" name="groupCode">
                        </div>
                    </div>
                </div>

                <div class="row text-info" *ngIf="this.group.users.length > 0">Members:</div>
                <div class="row">
                    <div class="col-12 cellListB">
                        <ul class="list-group">
                            <li class="list-group-item" *ngFor="let user of this.group.users; let i = index;" (click)="onUserDelete(i)">{{user.name}}</li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="form-group row">
                    <div class="col-12">
                        <button class="btn btn-primary" (click)="onNewClick()">New</button>
                        &nbsp;
                        <button class="btn btn-success" (click)="onSubmitClick()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-4 cellB">
            <div class="row">
                <div class="col text-center text-info">
                    Available Users:
                </div>
            </div>
            <div class="row cellListA">
                <div class="col">
                    <ul class="list-group scrollable-list">
                        <ng-container *ngFor="let item of this.users; let i = index;">
                            <li class="list-group-item" *ngIf="item.status == 'active' && this.isNotSelected(item.uid)" (click)="onUserSelect(i)">{{item.name}}
                                <small class="text-muted">({{item.uid}})</small>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>