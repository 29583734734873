<div class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card">
      <div class="card-block">
        <img class="mx-auto d-block" width="40%" height="auto" src="assets/images/handson.png" alt="Logo">
        <h2 class="text-center">Password Reset</h2>
        <form (submit)="onSubmit()">
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" [(ngModel)]="password" name="password" required>	
          </div>
          <div class="form-group">
          	<label for="password2">Repeat password</label>
          	<input type="password" class="form-control" [(ngModel)]="password2" name="password2" required>
          </div>
          <div class="form-group">
              <input type="submit" class="btn btn-primary btn-block" value="Submit">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
