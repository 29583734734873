import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

import { Group } from '../../models/Group';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  name: string;
  email: string;
  password: string;
  code: string;

  constructor(
    public router: Router,
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    this.name = '';
    this.email = '';
    this.password = '';
    this.code = '';
  }

  ngOnInit() {
  }

  onSubmit() {
    this.authService.register(this.name, this.email, this.password, this.code).subscribe(data => {
      if (data['status'] === 'success') {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
        this.router.navigate(['/']);
      } else { // display error
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }
}
