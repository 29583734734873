
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor (
        private authService: AuthService,
        private router: Router
    ) {}

    canActivate(): Observable<boolean> {
        return this.authService.getAuth().pipe(map(data => {
            //console.log('GUARD:' + data['status']);
            if (data['status'] !== 'success') {
                this.router.navigate(['/login']);
                return false;
            } else {
                return true;
            }
        }));
    }
}
