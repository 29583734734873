import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { Role } from '../models/Role';
import { Group } from '../models/Group';
import { Category } from '../models/Category';
import { Service } from '../models/Service';


@Injectable()
export class VarsService {
  // constants
  serverURL: string;
  serviceURL: string;
  user_status: string[];
  status: string[];
  type: string[];
  roles: Role[]; // all user roles
  groups: Group[]; // all user groups
  categories: Category[]; // all request categories
  services: Service[]; // all service types

  // variables
  emailAction: string;
  token: string;
  isLoggedIn: boolean;
  isSidebarCollapsed: boolean;
  user: User; // authenticated user with actions and features
  rid_idx: number; // current user role index (can only have one active)
  action_idx: number; // currently select action index
  count: number; // number of actions
  
  constructor(
  ) {
    this.serverURL = 'https://housfapp.cbcs.usf.edu/'; // PRODUCTION
    //this.serverURL = 'https://cbcsres.it.usf.edu/handson/'; // PRODUCTION-old
    //this.serverURL = 'https://cbcsres.it.usf.edu/handsont/'; // DEVELOPMENT
    this.serviceURL = this.serverURL + 'server/service.php';
    this.user_status = ['assigned', 'accepted', 'rejected', 'canceled', 'completed'];
    this.status = ['open', 'confirmed', 'canceled', 'closed'];
    this.type = ['string', 'text', 'number', 'date', 'time', 'check', 'select'];
    this.roles = []; // user roles
    this.groups = []; // user groups
    this.categories = [];
    this.services = [];
    this.emailAction = '';
    this.token = '';
    this.isLoggedIn = false;
    this.isSidebarCollapsed = false;
    this.user = {uid: 0, status: 'active', name: '', email: '', verified: 0, roles: []};
    this.rid_idx = 0; // default to first role
    this.action_idx = -1; // currently selected action
    this.count = 0;
  }

  ngOnInit() {
  }

  isAuthenticated() {
    return this.isLoggedIn;
  }

  isAuthorized() {
    return true;
  }

  isSysAdmin() { // SysAdmin
    if (this.isLoggedIn) {
      if (this.user.roles[this.rid_idx].name === "sysadmin") {
        return true;
      }
    }
    return false;
  }

  isAdmin() { // Admin
    if (this.isLoggedIn) {
      if (this.user.roles[this.rid_idx].name === "admin") {
        return true;
      }
    }
    return false;
  }

  isClient() { // Client
    if (this.isLoggedIn) {
      if (this.user.roles[this.rid_idx].name === "client") {
        return true;
      }
    }
    return false;
  }

  isProvider() { // Provider
    if (this.isLoggedIn) {
      if (this.user.roles[this.rid_idx].name === "provider") {
        return true;
      }
    }
    return false;
  }

  isMultiRole() {
    if (this.isLoggedIn) {
      if (this.user.roles.length > 1) {
        return true;
      }
    }
    return false;
  }

  action_provider_status(action, uid) {
    for (let provider of action.providers) {
      if (provider.uid == uid) {
        return provider.status;
      }
    }
    return '';
  }

  getrid() {
    if (this.isLoggedIn) {
      return this.user.roles[this.rid_idx].rid;
    } else {
      return 0;
    }
  }

  getRole() {
    if (this.isLoggedIn) {
      return this.user.roles[this.rid_idx].name;
    } else {
      return '';
    }
  }

  getStatus() {
    if (this.isLoggedIn && this.action_idx > -1) {
      return this.user.roles[this.rid_idx].actions[this.action_idx].status;
    } else {
      return 'new';
    }
  }

  getUserStatus() {
    if (this.isLoggedIn && this.action_idx > -1) {
      return this.action_provider_status(this.user.roles[this.rid_idx].actions[this.action_idx], this.user.uid);
    } else {
      return '';
    }
  }

  getAction() {
    if (this.isLoggedIn && this.action_idx > -1) {
      return this.user.roles[this.rid_idx].actions[this.action_idx];
    } else {
      return null;
    }
  }
  getActionsList() {
    if (this.isLoggedIn) {
      return this.user.roles[this.rid_idx].actions;
    } else {
      return null;
    }
  }

  toggleSidebar() { // simply toggle the sidebar variable
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }
}
