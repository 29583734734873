<div class="container">
    <br>
    <div class="row select">
        <div class="col-12">
            <form>
                <div class="form-group row">
                    <div class="col-12">
                        <select class="custom-select form-control" #reportSelectFilter
                            (change)="loadReportData(reportSelectFilter.value)">
                            <option [value]="-1">Select Report</option>
                            <option *ngFor="let report_name of this.report_list; let i = index" [value]="i" [selected]="this.report_idx == i">
                                {{report_name}}</option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="row filter">
        <div class="col-8">
            <mat-form-field>
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            </mat-form-field>
        </div>
        <div class="col-4">
            <button type="button" class="btn btn-success" (click)="loadReportData(report_idx)">
                <i class="fa fa-refresh fa-2x" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <br>
    <div class="row report" *ngIf="report.filteredData.length > 0">
        <div class="col-12">
            <!-- dynamically build the report -->
            <mat-table #table [dataSource]="report" matSort class="mat-elevation-z8">
                <ng-container *ngFor="let column of report_columns[report_idx]" [cdkColumnDef]="column.columnDef">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>{{ column.header }} </mat-header-cell>
                    <mat-cell *cdkCellDef="let row">{{ column.cell(row) }} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <div class="save">
                <button *ngIf="report.filteredData.length > 0" type="button" class="btn btn-success"
                    (click)="saveAsCSV()">Export</button>
            </div>
        </div>
    </div>
</div>