import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

import { Category } from '../../models/Category';
import { Group } from '../../models/Group';
import { User } from '../../models/User';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {
  client_groups: Group[]; // list of all client groups
  client_users: User[]; // list of all client users
  category: Category; // group buffer

  constructor(
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    // instantiate a new category buffer
    this.category = { cid: 0, name: '', review: 1, access: 'all', priority: 0, groups: [], users: [] };
  }

  ngOnInit() {
    // load client groups array
    this.authService.getGroups(0, 3).subscribe(data => {
      if (data['status'] === 'success') {
        this.client_groups = data['groups'];
      }
    });
    // load client users array
    this.authService.getRoleUsers(3).subscribe(data => {
      if (data['status'] === 'success') {
        this.client_users = data['users'];
      }
    });
  }

  // return label of the selected option
  getOptionLabel(group) {
    if (group.rid_name === '') { // group role has not been set yet.
      return 'Select';
    } else { // return role corresponding to the rid.
      return group.rid_name;
    }
  }

  // update access type
  onAccessUpdate(value) {
    if (value == 0) {
      this.category.access = 'all';
    } else if (value == 1) {
      this.category.access = 'group';
    } else {
      this.category.access = 'user';
    }
  }

  // update review
  onChecked() {
    this.category.review = this.category.review == 1 ? 0 : 1
  }

  // set currently selected category
  onCategorySelect(idx) {
    this.category.cid = this.vars.categories[idx].cid;
    this.category.name = this.vars.categories[idx].name;
    this.category.review = this.vars.categories[idx].review;
    this.category.access = this.vars.categories[idx].access;
    this.category.priority = this.vars.categories[idx].priority;
    this.category.groups = this.vars.categories[idx].groups;
    this.category.users = this.vars.categories[idx].users;
  }

  // clear the category buffer
  onNewClick() {
    this.category.cid = 0;
    this.category.name = '';
    this.category.review = 1;
    this.category.access = 'all';
    this.category.priority = 0;
    this.category.groups = [];
    this.category.users = [];
  }

  onGroupSelect(group) { // add gid to the list
    this.category.groups.push(group);
  }
  onGroupDelete(idx) { // remove gid from the list
    this.category.groups.splice(idx, 1);
  }
  groupNotSelected(gid) {
    for (let item of this.category.groups) {
      if (item.gid === gid) {
        return false;
      }
    }
    return true;
  }

  onUserSelect(user) { // add uid to the list
    this.category.users.push(user);
  }
  onUserDelete(idx) { // remove uid from the list
    this.category.users.splice(idx, 1);
  }
  userNotSelected(uid) {
    for (let item of this.category.users) {
      if (item.uid === uid) {
        return false;
      }
    }
    return true;
  }

  // update group in DB
  onSubmitClick() {
    this.authService.updateCategory(this.category).subscribe(data => {
      if (data['status'] === 'success') { // update successful
        this.category.cid = data['value'];
        // re-load groups array
        this.authService.getCategories(0, 0).subscribe(data => {
          if (data['status'] === 'success') { // return rules or empty array
            this.vars.categories = data['categories'];
            this.modalService.open(ModalComponent).componentInstance.message = 'Records Updated';
          }
        });
      } else {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }
}
