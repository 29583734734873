// import modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { CdkTableModule } from '@angular/cdk/table';
import { NgHttpLoaderModule } from 'ng-http-loader';

// import services
import { AuthService } from './services/auth.service';
import { VarsService } from './services/vars.service';

// import guards
import { AuthGuard } from './guards/auth.guard';

// import pipes
import { UserStudent } from './pipes/user-student.pipe';
import { UserTeacher } from './pipes/user-teacher.pipe';
import { ActionOpen } from './pipes/action-open.pipe';
import { ActionModule } from './pipes/action-module.pipe';

// import components
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { ModalComponent } from './components/modal/modal.component';
import { GroupsComponent } from './components/groups/groups.component';
import { UsersComponent } from './components/users/users.component';
import { AboutComponent } from './components/about/about.component';
import { ConfigureComponent } from './components/configure/configure.component';
import { FeaturesComponent } from './components/features/features.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ManageComponent } from './components/manage/manage.component';
import { AdminComponent } from './components/admin/admin.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ClientComponent } from './components/client/client.component';
import { ProviderComponent } from './components/provider/provider.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { ResetComponent } from './components/reset/reset.component';
import { ServicesComponent } from './components/services/services.component';

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'login', component: LoginComponent},
  {path: 'reset', component: ResetComponent},
  {path: 'configure', component: ConfigureComponent, canActivate: [AuthGuard]},
  {path: 'manage', component: ManageComponent, canActivate: [AuthGuard]},
  {path: 'admin', component: AdminComponent, canActivate: [AuthGuard]},
  {path: 'client', component: ClientComponent, canActivate: [AuthGuard]},
  {path: 'provider', component: ProviderComponent, canActivate: [AuthGuard]},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    PageNotFoundComponent,
    HomeComponent,
    ModalComponent,
    UserStudent,
    UserTeacher,
    ActionOpen,
    ActionModule,
    GroupsComponent,
    UsersComponent,
    AboutComponent,
    ConfigureComponent,
    FeaturesComponent,
    ReportsComponent,
    ManageComponent,
    AdminComponent,
    CategoriesComponent,
    ClientComponent,
    ProviderComponent,
    ModalConfirmComponent,
    ResetComponent,
    ServicesComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    CdkTableModule
  ],
  providers: [
    AuthService,
    VarsService,
    AuthGuard,
    NgbActiveModal,
    DatePipe
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    ModalComponent,
    ModalConfirmComponent
  ]
})
export class AppModule { }
