import { Pipe } from '@angular/core';

@Pipe({
    name: 'ActionOpen'
})
export class ActionOpen {
    transform(objects: any[]): any[] {
        if (objects) {
            return objects.filter(object => {
                return object.status === 'open';
            });
        }
    }
}
