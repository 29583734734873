import { Component, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent {
  report_idx: number;
  report_list: string[];
  report_columns: any[][];
  report: any;
  displayedColumns: any[][];

  @ViewChild(MatSort) sort: MatSort;
  // following solves the problem of sort before refresh
  @ViewChild(MatSort) set content(sort: MatSort) {
    this.report.sort = sort;
  }

  constructor(
    private authService: AuthService
  ) {
    this.report_idx = -1;
    this.report_list = ['Actvity Report'];
    this.report_columns = [
      [{ columnDef: 'aid', header: 'ID', cell: (element: any) => `${element.aid}` },
      { columnDef: 'label', header: 'Label', cell: (element: any) => `${element.label}` },
      { columnDef: 'owner', header: 'Owner', cell: (element: any) => `${element.owner}` },
      { columnDef: 'notes', header: 'Notes', cell: (element: any) => `${element.notes}` },
      { columnDef: 'status', header: 'Status', cell: (element: any) => `${element.status}` },
      { columnDef: 'assigned', header: 'Assigned To', cell: (element: any) => `${element.assigned}` },
      { columnDef: 'created_dt', header: 'Created', cell: (element: any) => `${element.created_dt}` },
      { columnDef: 'updated_dt', header: 'Updated', cell: (element: any) => `${element.updated_dt}` }]
    ];
    this.report = new MatTableDataSource();  
  }

  loadReportData(idx) {
    this.report_idx = idx;
    if (idx < 0) {
      this.report = new MatTableDataSource();
    } else {
      this.authService.getReports(idx).subscribe(data => {
        if (data['status'] === 'success') {
          this.displayedColumns = this.report_columns[this.report_idx].map(c => c.columnDef);
          this.report.data = data['report'];
          this.report.sort = this.sort;
        }
      });
    }
  }

  applyFilter(filterValue: string) {
    this.report.filter = filterValue.trim().toLowerCase();
  }

  saveAsCSV() {
    //console.log("Saving CSV...");
    const file = new AngularCsv(this.report.filteredData, 'My Report');
  }
}