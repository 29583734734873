<ng-container *ngIf="vars.isLoggedIn">
    <div class="row canvas-provider">
        <div [ngClass]="vars.isSidebarCollapsed ? 'col-md-12' : 'col-md-8'">
            <ng-container *ngIf="action.aid > 0">
                <div class="row">
                    <div class="col-4 feature-item">Request #</div>
                    <div class="col-8 feature-name">
                        {{action.name}}
                        <small class="action-text-dark"> {{action.category.name}} {{action.service.name}} for
                            {{action.client.name}}</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 feature-item">Notes</div>
                    <div class="col-8 feature-detail">
                        <textarea class="feature-textarea-disabled" rows="3" disabled [(ngModel)]="action.notes"
                            placeholder="Enter request notes (optional)"></textarea>
                    </div>
                </div>
                <div *ngFor="let item of action.providers; let i = index" class="row">
                    <div *ngIf="i > 0 || (status === 'confirmed')" class="col-4 feature-item">&nbsp;</div>
                    <div *ngIf="i == 0 && (status === 'canceled' || status === 'closed')" class="col-4 feature-item">
                        Interpreters</div>
                    <div class="col-3 feature-name">
                        {{item.name}} <small class="provider-label">({{item.action_status}})</small>
                    </div>
                    <div *ngIf="(item.uid == this.vars.user.uid) && (status === 'confirmed')" class="col-5 provider-text">
                        <textarea class="feature-textarea" rows="1" [(ngModel)]="this.notes"
                            placeholder="Enter notes (optional)"></textarea>
                    </div>
                    <div *ngIf="(item.uid != this.vars.user.uid) || (status !== 'confirmed')" class="col-5">
                        <textarea class="feature-textarea-disabled provider-text" rows="1" disabled>{{item.action_notes}}</textarea>
                    </div>
                </div>
                &nbsp; &nbsp;
                <!-- Features -->
                <ng-container>
                    <div class="row" *ngFor="let feature of action.features; let i = index">
                        <ng-container *ngIf="feature.hidden != 2 && feature.hidden != 3">
                            <!-- disable with *ngIf="isVisible(feature) -->
                            <div class="col-4 feature-item">
                                {{feature.name}}
                            </div>
                            <!-- STRING -->
                            <div *ngIf="feature.type == 'string'" class="col-8 feature-disabled">
                                {{feature.value}}
                            </div>
                            <!-- TEXT -->
                            <div *ngIf="feature.type == 'text'" class="col-8 feature-disabled">
                                {{feature.value}}
                            </div>
                            <!-- NUMBER -->
                            <div *ngIf="feature.type == 'number'" class="col-8 feature-disabled">
                                {{feature.value}}
                            </div>
                            <!-- DATE -->
                            <div *ngIf="feature.type == 'date'" class="col-8 feature-disabled">
                                {{displayDate(feature.date)}}
                            </div>
                            <!-- TIME -->
                            <div *ngIf="feature.type == 'time'" class="col-8 feature-disabled">
                                {{displayTime(feature.time)}}
                            </div>
                            <!-- CHECK -->
                            <div *ngIf="feature.type == 'check'" class="col-8 d-inline-block">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="featureCheck"
                                        [checked]="feature.value == 1" disabled>
                                    <label class="custom-control-label" for="featureCheck">{{feature.notes}}</label>
                                </div>
                            </div>
                            <!-- SELECT -->
                            <div *ngIf="feature.type == 'select'" ngbDropdown class="col-8 feature-disabled">
                                {{getOptionLabel(feature)}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- Buttons -->
                &nbsp; &nbsp;
                <div class="form-group row">
                    <div class="col-12 text-right">
                        <ng-container *ngIf="notes != '' && (status == 'confirmed')">
                            <button class="btn btn-sm btn-warning" (click)="onButtonClick('update')"
                                ngbTooltip="Update notes"><i class="fa fa-pencil fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'confirmed' && providerStatus() == 'assigned'">
                            <button class="btn btn-sm btn-danger" (click)="onButtonClick('reject')"
                                ngbTooltip="Reject this request"><i class="fa fa-ban fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'confirmed' && providerStatus() == 'assigned'">
                            <button class="btn btn-sm btn-success" (click)="onButtonClick('accept')"
                                ngbTooltip="Accept this request"><i class="fa fa-check fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'confirmed' && providerStatus() == 'accepted'">
                            <button class="btn btn-sm btn-danger" (click)="onButtonClick('cancel')"
                                ngbTooltip="Cancel this request"><i class="fa fa-ban fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'confirmed' && providerStatus() == 'accepted'">
                            <button class="btn btn-sm btn-success" (click)="onButtonClick('complete')"
                                ngbTooltip="Complete this request"><i class="fa fa-check fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- Action list with selection (client/provider) -->
        <div class="col-md-4 cellA" [ngbCollapse]="vars.isSidebarCollapsed">
            <div class="row-sm cellB">
                <div class="row">
                    <div class="col-12">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn-lg" ngbDropdownToggle>{{status}}</button>
                            <div ngbDropdownMenu>
                                <ng-container *ngFor="let status of vars.status; let i = index">
                                    <button *ngIf="(i === 1) || (i === 3)" ngbDropdownItem class="dropdown-item"
                                        (click)="onStatusSelect(i)">{{status}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">&nbsp;</div>
                </div>
                <div class="row cellListA">
                    <div class="col-12">
                        <ul *ngIf="vars.user.roles[vars.rid_idx].actions != null && vars.user.roles[vars.rid_idx].actions.length > 0"
                            class="list-group">
                            <ng-container *ngFor="let action of vars.user.roles[vars.rid_idx].actions; let i = index;">
                                <li *ngIf="(action.status === this.status)" class="list-group-item"
                                    [ngClass]="{'active': this.vars.action_idx == i, 'list-group-item-warning': this.urgent(action)}"
                                    (click)="onActionClick(i)">{{action.name}}
                                    <small *ngIf="action.status === 'open'" class="action-text"> {{action.service.name}}
                                        {{getConfirmed(action)}}
                                        / {{action.required}}</small>
                                    <small *ngIf="action.status !== 'open'" class="action-text"> {{action.service.name}}
                                    </small>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn cellD" (click)="vars.toggleSidebar()">
            <span class="fa"
                [ngClass]="vars.isSidebarCollapsed ? 'fa-arrow-circle-left' : 'fa-arrow-circle-right'"></span>
        </button>
    </div>
</ng-container>