import { Pipe } from '@angular/core';

@Pipe({
    name: 'UserStudent'
})
export class UserStudent {
    transform(objects: any[]): any[] {
        if (objects) {
            return objects.filter(object => {
                return object.role === 'student';
            });
        }
    }
}