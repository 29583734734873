<div class="container">
    <br>
    <div class="row">
        <div class="col-md-4 cellA">
            <div class="row cellListA">
                <div class="col">
                    <ul class="list-group">
                        <ng-container *ngFor="let item of this.vars.categories; let i = index;">
                            <li class="list-group-item" [ngClass]="{'active': this.category.cid == item.cid}"
                                (click)="onCategorySelect(i)">{{item.name}}
                                <small class="text-muted">({{item.cid}})</small>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4 cellB">
            <form>
                <div class="row text-info">
                    <div class="col-6">
                        Category ID:
                        <b *ngIf="this.category != null" class="text-primary">{{category.cid}}</b>
                    </div>
                    <div class="col-6 form-check">
                        <input type="checkbox" class="form-check-input" id="adminCheck" [checked]="category.review == 1"
                            (change)="onChecked()">
                        <label class="form-check-label text-info" for="adminCheck">Needs Review</label>
                    </div>
                </div>
                <br>
                <div class="row">
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="categoryPriority" class="text-info col-form-label">Display Priority:
                                    <b class="text-primary">{{category.priority}}</b>
                                </label>
                                <input type="range" min="0" value="0" max="100" class="form-control" [(ngModel)]="category.priority"
                                    name="categoryPriority">
                            </div>
                        </div>
                    </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="categoryName" class="text-info col-form-label">Name:</label>
                            <input type="text" class="form-control" placeholder="Enter category name"
                                [(ngModel)]="category.name" name="categoryName">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group row">
                            <label for="accessSelect" class="text-info col-form-label">Access:</label>
                            <select name="accessSelect" class="custom-select form-control" #accessType
                                (change)="onAccessUpdate(accessType.value)">
                                <option [value]="0">All</option>
                                <option [value]="1" [selected]="this.category.access == 'group'">Group</option>
                                <option [value]="2" [selected]="this.category.access == 'user'">User</option>
                            </select>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="this.category.access == 'group' && this.category.groups.length > 0">
                    <div class="row text-info">Groups:</div>
                    <div class="row">
                        <div class="col-12 cellListB">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let group of this.category.groups; let i = index;"
                                    (click)="onGroupDelete(i)">{{group.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="this.category.access == 'user' && this.category.users.length > 0">
                    <div class="row text-info">Users:</div>
                    <div class="row">
                        <div class="col-12 cellListB">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let user of this.category.users; let i = index;"
                                    (click)="onUserDelete(i)">{{user.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <br>
                <div class="form-group row">
                    <div class="col-12">
                        <button class="btn btn-primary" (click)="onNewClick()">New</button>
                        &nbsp;
                        <button class="btn btn-success" (click)="onSubmitClick()">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-4 cellB">
            <ng-container *ngIf="this.category.access == 'group'">
                <div class="row">
                    <div class="col text-center text-info">
                        Available Groups:
                    </div>
                </div>
                <div class="row cellListA">
                    <div class="col">
                        <ul class="list-group">
                            <ng-container *ngFor="let item of this.client_groups; let i = index;">
                                <li class="list-group-item" *ngIf="this.groupNotSelected(item.gid)"
                                    (click)="onGroupSelect(item)">{{item.name}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="this.category.access == 'user'">
                <div class="row">
                    <div class="col text-center text-info">
                        Available Users:
                    </div>
                </div>
                <div class="row cellListA">
                    <div class="col">
                        <ul class="list-group">
                            <ng-container *ngFor="let item of this.client_users; let i = index;">
                                <li class="list-group-item" *ngIf="this.userNotSelected(item.uid)"
                                    (click)="onUserSelect(item)">{{item.name}}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>