import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

import { Feature } from '../../models/Feature';
import { Feature_Option } from '../../models/Feature_Option';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {
  features: Feature[]; // list of all features (with options if type=select)
  feature: Feature; // feature buffer
  feature_option: Feature_Option; // feature option buffer
  filter_cid: number;

  constructor(
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    this.filter_cid = 0;
    // instantiate a new group buffer
    this.feature = { fid: 0, cid: 0, type: '', name: '', notes: '', maximum: 0, priority: 0, hidden: 0, required: 0, options: [] };
    this.feature_option = { idx: 0, label: '' };
    this.filter_cid = 0;
  }

  ngOnInit() {
    // load groups array
    this.authService.getFeatures(0, 0, 0).subscribe(data => {
      if (data['status'] === 'success') {
        this.features = data['features'];
      }
    });
  }

  onFilterCategorySelect(cid) {
    this.filter_cid = cid;
  }

  onCategoryUpdate(cid) {
    this.feature.cid = cid;
  }

  onTypeUpdate(type) {
    this.feature.type = type;
  }

  onHiddenUpdate(hidden) {
    this.feature.hidden = hidden;
  }

  // append new option to the feature
  onPlusClick() {
    let idx = this.feature.options.length;
    this.feature.options.push({ idx: idx, label: this.feature_option.label });
    this.feature_option.label = ''; // clear the buffer
  }
  // remove this option from the feature
  onMinusClick(idx) {
    console.log("SPLICE:" + idx);
    this.feature.options.splice(idx, 1);
  }

  // flip the required indicator
  onCheckedRequired() {
    this.feature.required = this.feature.required == 1 ? 0 : 1
  }

  // set currently selected feature
  onFeatureSelect(idx) {
    this.feature.fid = this.features[idx].fid;
    this.feature.cid = this.features[idx].cid;
    this.feature.type = this.features[idx].type;
    this.feature.name = this.features[idx].name;
    this.feature.notes = this.features[idx].notes;
    this.feature.maximum = this.features[idx].maximum;
    this.feature.priority = this.features[idx].priority;
    this.feature.hidden = this.features[idx].hidden;
    this.feature.required = this.features[idx].required;
    this.feature.options = this.features[idx].options;
  }

  // clear the feature buffer
  onNewClick() {
    this.feature_option.label = '';
    this.feature.fid = 0;
    this.feature.cid = 0;
    this.feature.type = '';
    this.feature.name = '';
    this.feature.notes = '';
    this.feature.maximum = 0;
    this.feature.priority = 0;
    this.feature.hidden = 0;
    this.feature.required = 0;
    this.feature.options = [];
    console.log("NEW");
  }

  // update group in DB
  onSubmitClick() {
    this.authService.updateFeature(this.feature).subscribe(data => {
      if (data['status'] === 'success') { // update successful
        this.feature.fid = data['value'];
        // re-load groups array
        this.authService.getFeatures(0, 0, 0).subscribe(data => {
          if (data['status'] === 'success') {
            this.features = data['features'];
            this.modalService.open(ModalComponent).componentInstance.message = 'Records Updated';
          }
        });
      } else {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
    });
  }
}
