import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; // bootstap modal
import { ModalComponent } from '../modal/modal.component'; // custom modal

declare var ga: Function;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  email: string;
  password: string;
  reset: boolean;

  constructor(
    public router: Router,
    public authService: AuthService,
    public vars: VarsService,
    private modalService: NgbModal // instantiate modal service
  ) {
    this.email = '';
    this.password = '';
    this.reset = false;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log('GA SENDING:' + event.urlAfterRedirects);
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });
  }

  ngOnInit() {
    this.reset = false;
  }

  onReset() {
    this.reset = true;
  }

  onSubmit() {
    this.authService.login(this.email, this.password).subscribe(data => {
      if (data['status'] === 'success') {
        ga('set', 'userId', data['user'].email); // Set the user ID using signed-in user_id.
        this.vars.user = data['user'];
        this.vars.rid_idx = 0; // first role - must have at least one at this point
        this.authService.getActionCount(this.vars.user.uid, this.vars.user.roles[this.vars.rid_idx].rid).subscribe(data => {
          if (data['status'] === 'success') {
            this.vars.count = data['count'];
            this.vars.isLoggedIn = true;
            this.router.navigate(['/']);
            // load user roles
            this.authService.getRoles().subscribe(data => {
              if (data['status'] === 'success') {
                this.vars.roles = data['roles'];
              }
            });
            // load provider groups only
            if (this.vars.user.roles[this.vars.rid_idx].rid < 3) { // admins
              this.authService.getGroups(0, 3).subscribe(data => {
                if (data['status'] === 'success') {
                  this.vars.groups = data['groups'];
                }
              });
            } else {
              this.authService.getGroups(this.vars.user.uid, 3).subscribe(data => {
                if (data['status'] === 'success') {
                  this.vars.groups = data['groups'];
                }
              });
            }
            // load request categories
            this.authService.getCategories(0, 0).subscribe(data => {
              if (data['status'] === 'success') {
                this.vars.categories = data['categories'];
              }
            });
            // load service types
            this.authService.getServices(0, 0).subscribe(data => {
              if (data['status'] === 'success') {
                this.vars.services = data['services'];
              }
            });
          } else {
            this.modalService.open(ModalComponent).componentInstance.message = data['value'];
          }
        });
      } else { // display modal error
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
        this.vars.isLoggedIn = false;
        this.router.navigate(['/']);
      }
    });
  }

  onSubmitReset() {
    this.authService.forgotPassword(this.email).subscribe(data => {
      if (data['status'] === 'success') {
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      } else { // display error
        this.modalService.open(ModalComponent).componentInstance.message = data['value'];
      }
      this.vars.isLoggedIn = false;
      this.router.navigate(['/']);
    });
  }
}
