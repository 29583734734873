<!--img class="img-responsive background" src="assets/images/home3.jpeg" width="100%" height="60%">
-->
<div *ngIf="!vars.isLoggedIn" class="row">
    <div class="col-md-6 offset-md-3">
        <div class="card">
            <div class="card-block">
                <img class="mx-auto d-block" width="40%" height="auto" src="assets/images/handson.png" alt="">
                <p class="title">Welcome to Hands On USF web app.</p>
                <p class="text">
                    In order to use this application you must <a href="#" routerLink="/register">Register</a> or <a
                        href="#" routerLink="/login">Login</a> first. Once registered and logged in, you will be able to
                    submit requests for service then monitor and manage your appointments inside myHandsOn tab.
                </p>
                <p class="text2">
                    The request/appointment will be staffed with an appropriately qualified interpreter. While it is
                    part of our mission to provide mentorship opportunities to students, we will only assign
                    student/professional teams to appropriate situations. If a situation is not suited for an Intern, it
                    will be staffed solely with professional, nationally certified Interpreters. We are committed to our
                    students, but also to the communities and populations we serve.
                </p>
            </div>
        </div>
    </div>
</div>