<div class="row">
  <div class="col-md-6 offset-md-3">
    <div class="card">
      <div class="card-block">
        <img class="mx-auto d-block" width="40%" height="auto" src="assets/images/handson.png" alt="">
        <h2 class="text-center">HandsOn USF Registration</h2>
        <form (submit)="onSubmit()">
          <div class="form-group">
            <label for="name">Your Name</label>
            <input type="text" class="form-control" [(ngModel)]="name" name="name" required>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" [(ngModel)]="email" name="email" required>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" [(ngModel)]="password" name="password" required>
          </div>
          <div class="form-group">
            <label for="code">CODE (optional)</label>
            <input type="text" class="form-control" [(ngModel)]="code" name="code" required>
          </div>
          <div class="form-group">
            <input type="submit" class="btn btn-primary btn-block" value="Submit">
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
