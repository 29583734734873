
import {map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { VarsService } from '../../services/vars.service'; // shared data service

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService,
    public vars: VarsService,
  ) {
  }

  ngOnInit() {
    // check if the session is already active
    this.authService.getAuth().pipe(map(data => {
      if (data['status'] === 'success') {
          this.vars.isLoggedIn = true;
      }
    }));
  }

  switchRole() {
    if (this.vars.isLoggedIn) {
      this.vars.action_idx = -1; // clear the selected action
      this.router.navigate(['/']); // go back to the home screen
      if (this.vars.user.roles.length > 1) {
        if (this.vars.rid_idx < (this.vars.user.roles.length - 1)) {
          this.vars.rid_idx++;
            // load provider groups only
            if (this.vars.user.roles[this.vars.rid_idx].rid < 3) { // admins
              this.authService.getGroups(0, 3).subscribe(data => {
                if (data['status'] === 'success') {
                  this.vars.groups = data['groups'];
                }
              });
            } else {
              this.authService.getGroups(this.vars.user.uid, 3).subscribe(data => {
                if (data['status'] === 'success') {
                  this.vars.groups = data['groups'];
                }
              });
            }
            // load request categories
            this.authService.getCategories(0, this.vars.user.roles[this.vars.rid_idx]).subscribe(data => {
              if (data['status'] === 'success') {
                this.vars.categories = data['categories'];
              }
            });
            // load service types
            this.authService.getServices(0, this.vars.user.roles[this.vars.rid_idx]).subscribe(data => {
              if (data['status'] === 'success') {
                this.vars.services = data['services'];
              }
            });
          // DO NOT pull all actions here... just the open count (or confirmed)
          this.authService.getActionCount(this.vars.user.uid, this.vars.user.roles[this.vars.rid_idx].rid).subscribe(data => {
            if (data['status'] === 'success') { // populate actions for this user-role
              this.vars.count = data['count'];
            }
          });  
        } else {
          this.vars.rid_idx = 0; // sysadmin has no actions
        }
      }
    }
  }

  // dropdown if if user has more than one role
  userLabel() {
    let label = this.vars.user.name;
    if (this.vars.user.roles.length > 0) { // include role
      label += ' (' + this.vars.getRole() + ')';
    }
    return label;
  }
  
  onLogoutClick() {
    this.authService.logout().subscribe(data => {
      if (data['status'] === 'success') {
        this.router.navigate(['/']);
      } else { // display error (if ever)
        console.log(data['value']);
      }
    });
  }
}
