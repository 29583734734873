<ng-container *ngIf="vars.isLoggedIn">
    <div class="row canvas-client">
        <div [ngClass]="vars.isSidebarCollapsed ? 'col-md-12' : 'col-md-8'">
            <ng-container *ngIf="status === 'new' || action.aid > 0">
                <!-- only show group if new request -->
                <div *ngIf="gid_num > 1 && status === 'new'" class="row">
                    <div class="col-4 feature-item">Group</div>
                    <div class="col-8 feature-name">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn" ngbDropdownToggle>{{action.group.name}}</button>
                            <div ngbDropdownMenu>
                                <ng-container *ngFor="let group of vars.groups; let i = index">
                                    <button ngbDropdownItem class="dropdown-item"
                                        (click)="onGroupSelect(group)">{{group.name}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- only show type and owner if new request -->
                <div *ngIf="cid_num > 1 && status === 'new'" class="row">
                    <div class="col-4 feature-item">Request Category</div>
                    <div class="col-8 feature-name">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn" ngbDropdownToggle>{{action.category.name}}</button>
                            <div ngbDropdownMenu>
                                <ng-container *ngFor="let category of vars.categories; let i = index">
                                    <button ngbDropdownItem class="dropdown-item"
                                        (click)="onCategorySelect(category)">{{category.name}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="sid_num > 1 && (status !== 'canceled' && status !== 'closed')" class="row">
                    <div class="col-4 feature-item">Service Type</div>
                    <div class="col-8 feature-name">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn" ngbDropdownToggle>{{action.service.name}}</button>
                            <div ngbDropdownMenu>
                                <ng-container *ngFor="let service of vars.services; let i = index">
                                    <button ngbDropdownItem class="dropdown-item"
                                        (click)="onServiceSelect(service)">{{service.name}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 feature-item">Request #</div>
                    <div class="col-8 feature-name">
                        {{action.name}}
                        <small class="action-text-dark"> {{action.category.name}} {{action.service.name}} for
                            {{action.client.name}}</small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 feature-item">Notes</div>
                    <div class="col-8 feature-detail">
                        <textarea *ngIf="status === 'new' || status === 'open' || status === 'confirmed'"
                            class="feature-textarea" rows="3" [(ngModel)]="action.notes"
                            placeholder="Enter request notes (optional)"></textarea>
                        <textarea *ngIf="status === 'canceled' || status === 'closed'" class="feature-textarea-disabled"
                            rows="3" disabled [(ngModel)]="action.notes"
                            placeholder="Enter request notes (optional)"></textarea>
                    </div>
                </div>
                <div *ngIf="status === 'new' || status === 'open' || status === 'confirmed'" class="row">
                    <div class="col-4 feature-item">Interpreters</div>
                    <div class="col-8 d-inline-block">
                        <div class="input-group mb-3">
                            <input type="text" maxlength="2" size="2" class="feature-text" [(ngModel)]="action.required"
                                (change)="loadActionProviders(false)">
                            <div class="input-group-append input-group-text">
                                <input type="range" min="1" max=10 [(ngModel)]="action.required"
                                    (change)="loadActionProviders(false)">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngFor="let item of action.providers; let i = index" class="row">
                    <div *ngIf="i > 0 || (status === 'new' || status === 'open' || status === 'confirmed')"
                        class="col-4 feature-item">&nbsp;</div>
                    <div *ngIf="i == 0 && (status === 'canceled' || status === 'closed')" class="col-4 feature-item">
                        Interpreters</div>
                    <div class="col-3 feature-name">
                        {{item.name}} <small class="provider-label">({{item.action_status}})</small>
                    </div>
                    <div class="col-5">
                        <textarea class="feature-textarea-disabled provider-text" rows="1" disabled>{{item.action_notes}}</textarea>
                    </div>
                </div>
                &nbsp; &nbsp;
                <!-- Features -->
                <ng-container *ngIf="status === 'new' || status === 'open' || status === 'confirmed'">
                    <div class="row" *ngFor="let feature of action.features; let i = index">
                        <ng-container *ngIf="feature.hidden != 1 && feature.hidden != 3">
                            <div class="col-4 feature-item">
                                {{feature.name}}
                            </div>
                            <!-- STRING -->
                            <div *ngIf="feature.type == 'string'" class="col-8 d-inline-block">
                                <input type="text" maxlength="feature.maximum" class="feature-text"
                                    [(ngModel)]="feature.value" name={{feature.fid}}
                                    (change)="onFeatureUpdate(feature, feature.value)">
                            </div>
                            <!-- TEXT -->
                            <div *ngIf="feature.type == 'text'" class="col-8 d-inline-block">
                                <textarea class="feature-textarea" rows="1" [(ngModel)]="feature.value"
                                    [attr.maxlength]="feature.maximum" name={{feature.fid}}
                                    (change)="onFeatureUpdate(feature, feature.value)"></textarea>
                            </div>
                            <!-- NUMBER -->
                            <div *ngIf="feature.type == 'number'" class="col-8 d-inline-block">
                                <div class="input-group mb-3">
                                    <input type="text" maxlength="3" size="3" class="feature-text"
                                        [(ngModel)]="feature.value" name={{feature.fid}}
                                        (change)="onFeatureUpdate(feature, feature.value)">
                                    <div class="input-group-append input-group-text">
                                        <input type="range" min="0" max={{feature.maximum}} [(ngModel)]="feature.value"
                                            (change)="onFeatureUpdate(feature, feature.value)">
                                    </div>
                                </div>
                            </div>
                            <!-- DATE -->
                            <div *ngIf="feature.type == 'date'" class="col-8">
                                <form class="form-inline">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <input class="form-control" placeholder="yyyy-mm-dd" name="dp"
                                                [(ngModel)]="feature.date" ngbDatepicker #d="ngbDatepicker">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary calendar fa fa-calendar"
                                                    (click)="d.toggle()" type="button"></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <!--pre>Date: {{ feature.date | json }}</pre-->
                            </div>
                            <!-- TIME -->
                            <div *ngIf="feature.type == 'time'" class="col-8 d-inline-block">
                                <ngb-timepicker [(ngModel)]="feature.time" [meridian]="true"></ngb-timepicker>
                                <!--pre>Time: {{feature.time | json}}</pre-->
                            </div>
                            <!-- CHECK -->
                            <div *ngIf="feature.type == 'check'" class="col-8 d-inline-block">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="featureCheck"
                                        [checked]="feature.value == 1" (change)="onFeatureChecked(feature)">
                                    <label class="custom-control-label" for="featureCheck">{{feature.notes}}</label>
                                </div>
                            </div>
                            <!-- SELECT -->
                            <div *ngIf="feature.type == 'select'" ngbDropdown class="col-8 d-inline-block">
                                <button class="btn" ngbDropdownToggle>{{getOptionLabel(feature)}}</button>
                                <div ngbDropdownMenu>
                                    <ng-container *ngFor="let option of feature.options; let j = index">
                                        <button ngbDropdownItem class="dropdown-item"
                                            (click)="onFeatureUpdate(feature, j.toString())">{{option.label}}</button>
                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngIf="status === 'canceled' || status === 'closed'">
                    <div class="row" *ngFor="let feature of action.features; let i = index">
                        <ng-container>
                            <!-- disable with *ngIf="isVisible(feature) -->
                            <div class="col-4 feature-item">
                                {{feature.name}}
                            </div>
                            <!-- STRING -->
                            <div *ngIf="feature.type == 'string'" class="col-8 feature-disabled">
                                {{feature.value}}
                            </div>
                            <!-- TEXT -->
                            <div *ngIf="feature.type == 'text'" class="col-8 feature-disabled">
                                {{feature.value}}
                            </div>
                            <!-- NUMBER -->
                            <div *ngIf="feature.type == 'number'" class="col-8 feature-disabled">
                                {{feature.value}}
                            </div>
                            <!-- DATE -->
                            <div *ngIf="feature.type == 'date'" class="col-8 feature-disabled">
                                {{displayDate(feature.date)}}
                            </div>
                            <!-- TIME -->
                            <div *ngIf="feature.type == 'time'" class="col-8 feature-disabled">
                                {{displayTime(feature.time)}}
                            </div>
                            <!-- CHECK -->
                            <div *ngIf="feature.type == 'check'" class="col-8 d-inline-block">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="featureCheck"
                                        [checked]="feature.value == 1" disabled>
                                    <label class="custom-control-label" for="featureCheck">{{feature.notes}}</label>
                                </div>
                            </div>
                            <!-- SELECT -->
                            <div *ngIf="feature.type == 'select'" ngbDropdown class="col-8 feature-disabled">
                                {{getOptionLabel(feature)}}
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- Buttons -->
                &nbsp; &nbsp;
                <!-- Buttons -->
                &nbsp; &nbsp;
                <div class="form-group row">
                    <div class="col-12 text-right">
                        <ng-container *ngIf="status == 'new' && stillRequired()">
                            <button class="btn btn-sm btn-success disabled" ngbTooltip="Missing required fields"><i
                                    class="fa fa-folder-open fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'new' && !stillRequired()">
                            <button class="btn btn-sm btn-success" [ngClass]="{'disabled': stillRequired()}"
                                (click)="onButtonClick('submit')" ngbTooltip="Submit"><i
                                    class="fa fa-folder-open fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'open' || status == 'confirmed'">
                            <button class="btn btn-sm btn-warning" (click)="onButtonClick('submit')"
                                ngbTooltip="Update this request"><i class="fa fa-pencil fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'open' || status == 'confirmed'">
                            <button class="btn btn-sm btn-danger" (click)="onButtonClick('cancel')"
                                ngbTooltip="Cancel this request"><i class="fa fa-ban fa-2x"></i></button>
                            &nbsp;
                        </ng-container>
                        <ng-container *ngIf="status == 'open'">
                            <button class="btn btn-sm btn-primary" (click)="onButtonClick('add')"
                                ngbTooltip="Create additional request"><i class="fa fa-plus fa-2x"></i></button>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>

        <!-- Action list with selection (client/provider) -->
        <div class="col-md-4 cellA" [ngbCollapse]="vars.isSidebarCollapsed">
            <div class="row-sm cellB">
                <div class="row">
                    <div class="col-12">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn-lg" ngbDropdownToggle>{{status}}</button>
                            <div ngbDropdownMenu>
                                <button ngbDropdownItem class="dropdown-item" (click)="onStatusSelect(-1)">new</button>
                                <ng-container *ngFor="let status of vars.status; let i = index">
                                    <button ngbDropdownItem class="dropdown-item"
                                        (click)="onStatusSelect(i)">{{status}}</button>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">&nbsp;</div>
                </div>
                <div class="row cellListA">
                    <div class="col-12">
                        <ul *ngIf="vars.user.roles[vars.rid_idx].actions != null && vars.user.roles[vars.rid_idx].actions.length > 0"
                            class="list-group">
                            <ng-container *ngFor="let action of vars.user.roles[vars.rid_idx].actions; let i = index;">
                                <li *ngIf="(action.status === this.status)" class="list-group-item"
                                    [ngClass]="{'active': this.vars.action_idx == i, 'list-group-item-warning': this.urgent(action)}"
                                    (click)="onActionClick(i)">
                                    {{action.name}}
                                    <small *ngIf="action.status === 'open'" class="action-text"> {{action.service.name}}
                                        {{getConfirmed(action)}}
                                        / {{action.required}}</small>
                                    <small *ngIf="action.status !== 'open'" class="action-text"> {{action.service.name}}
                                    </small>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn cellD" (click)="vars.toggleSidebar()">
            <span class="fa"
                [ngClass]="vars.isSidebarCollapsed ? 'fa-arrow-circle-left' : 'fa-arrow-circle-right'"></span>
        </button>
    </div>
</ng-container>